// Generated by ReScript, PLEASE EDIT WITH CARE


var teal = "#18c3bb";

var orange = "#ff7801";

var red = "#ff2e37";

var white = "#ffffff";

var gray = "#555";

var grayText = "#5c667a";

var lightGray = "#777";

var lighterGray = "#b8b8b8";

var lightestGray = "#e8e8e8";

var extraLightGray = "#f5f5f5";

var darkGray = "#98a0b0";

var darkerGray = "#292f3b";

var darkOrange = "#9d4e09";

var purple = "#933cdd";

var cinnabar = "#ea4c3a";

var yellowText = "#ffc107";

export {
  teal ,
  orange ,
  red ,
  white ,
  gray ,
  grayText ,
  lightGray ,
  lighterGray ,
  lightestGray ,
  extraLightGray ,
  darkGray ,
  darkerGray ,
  darkOrange ,
  purple ,
  cinnabar ,
  yellowText ,
}
/* No side effect */
