// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Svg from "../Svg/Svg.res.js";
import * as Colors from "../../styles/Colors.res.js";
import * as IconScss from "./Icon.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = IconScss;

var iconSize = "16";

var Margin = {};

function mapColor(color) {
  switch (color) {
    case "Teal" :
        return Colors.teal;
    case "Red" :
        return Colors.red;
    case "Gray" :
        return Colors.gray;
    case "GrayText" :
        return Colors.grayText;
    case "White" :
        return Colors.white;
    case "LightGray" :
        return Colors.lightGray;
    case "LighterGray" :
        return Colors.lighterGray;
    case "LightestGray" :
        return Colors.lightestGray;
    case "ExtraLightGray" :
        return Colors.extraLightGray;
    case "DarkGray" :
        return Colors.darkGray;
    case "DarkerGray" :
        return Colors.darkerGray;
    case "DarkOrange" :
        return Colors.darkOrange;
    case "Cinnabar" :
        return Colors.cinnabar;
    case "YellowText" :
        return Colors.yellowText;
    
  }
}

function style(color) {
  return {
          fill: mapColor(color)
        };
}

function iconClassName(size, margin, className) {
  var tmp;
  switch (size) {
    case "XXXS" :
        tmp = css.sizeXxxs;
        break;
    case "XXS" :
        tmp = css.sizeXxs;
        break;
    case "XS" :
        tmp = css.sizeXs;
        break;
    case "SM" :
        tmp = css.sizeSm;
        break;
    case "MD" :
        tmp = css.sizeMd;
        break;
    case "LG" :
        tmp = css.sizeLg;
        break;
    case "XL" :
        tmp = css.sizeXl;
        break;
    case "XXL" :
        tmp = css.sizeXxl;
        break;
    case "XXXL" :
        tmp = css.sizeXxxl;
        break;
    case "XXXXL" :
        tmp = css.sizeXxxxl;
        break;
    case "XXXXXL" :
        tmp = css.sizeXxxxxl;
        break;
    
  }
  var tmp$1;
  tmp$1 = margin !== undefined ? (
      margin === "Left" ? css.marginLeft : css.marginRight
    ) : "";
  return Cx.cx([
              css.icon,
              tmp,
              tmp$1,
              className
            ]);
}

function Icon(props) {
  var __className = props.className;
  var __viewBoxSize = props.viewBoxSize;
  var viewBoxSize = __viewBoxSize !== undefined ? __viewBoxSize : iconSize;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx(Svg.make, {
              title: props.title,
              viewBoxWidth: viewBoxSize,
              viewBoxHeight: viewBoxSize,
              className: iconClassName(props.size, props.margin, className),
              children: props.children
            });
}

var make = Icon;

export {
  css ,
  iconSize ,
  Margin ,
  mapColor ,
  style ,
  iconClassName ,
  make ,
}
/* css Not a pure module */
