// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as IconOk from "../../icons/IconOk.res.js";
import * as Spinner from "../../components/Spinner/Spinner.res.js";
import * as TextFieldScss from "./TextField.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TextFieldScss;

function autoCompleteToString(autoComplete) {
  switch (autoComplete) {
    case "Off" :
        return "off";
    case "On" :
        return "on";
    case "Notel" :
        return "notel";
    
  }
}

function domInputType(type_) {
  if (type_ === "Text") {
    return "text";
  } else {
    return "password";
  }
}

function TextField(props) {
  var __autoComplete = props.autoComplete;
  var __grouped = props.grouped;
  var __wrapperClassName = props.wrapperClassName;
  var __className = props.className;
  var __autoFocus = props.autoFocus;
  var leftIcon = props.leftIcon;
  var icon = props.icon;
  var status = props.status;
  var __disabled = props.disabled;
  var __placeholder = props.placeholder;
  var __type_ = props.type_;
  var type_ = __type_ !== undefined ? __type_ : "Text";
  var placeholder = __placeholder !== undefined ? __placeholder : "";
  var disabled = __disabled !== undefined ? __disabled : false;
  var autoFocus = __autoFocus !== undefined ? __autoFocus : false;
  var className = __className !== undefined ? __className : "";
  var wrapperClassName = __wrapperClassName !== undefined ? __wrapperClassName : "";
  var grouped = __grouped !== undefined ? __grouped : false;
  var autoComplete = __autoComplete !== undefined ? __autoComplete : "Off";
  var tmp;
  if (status !== undefined) {
    switch (status) {
      case "Error" :
          tmp = css.error;
          break;
      case "Valid" :
      case "Busy" :
          tmp = "";
          break;
      
    }
  } else {
    tmp = "";
  }
  var tmp$1;
  if (status !== undefined) {
    switch (status) {
      case "Error" :
          tmp$1 = icon !== undefined && icon !== undefined ? css.withRightSideAddon : "";
          break;
      case "Valid" :
      case "Busy" :
          tmp$1 = css.withRightSideAddon;
          break;
      
    }
  } else {
    tmp$1 = icon !== undefined ? css.withRightSideAddon : "";
  }
  var tmp$2;
  var exit = 0;
  if (status !== undefined) {
    switch (status) {
      case "Valid" :
          if (icon !== undefined) {
            exit = 1;
          } else {
            tmp$2 = JsxRuntime.jsx("label", {
                  children: JsxRuntime.jsx(IconOk.make, {
                        size: "MD",
                        color: "Teal"
                      }),
                  className: css.icon
                });
          }
          break;
      case "Error" :
          if (icon !== undefined) {
            exit = 1;
          } else {
            tmp$2 = null;
          }
          break;
      case "Busy" :
          tmp$2 = JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Spinner.make, {
                      size: "MD",
                      color: "Teal",
                      centered: true
                    }),
                className: css.spinner
              });
          break;
      
    }
  } else if (icon !== undefined) {
    exit = 1;
  } else {
    tmp$2 = null;
  }
  if (exit === 1) {
    tmp$2 = JsxRuntime.jsx("label", {
          children: JsxRuntime.jsx(icon.make, {
                size: "MD",
                color: "Gray"
              }),
          className: css.icon
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                leftIcon !== undefined ? JsxRuntime.jsx("label", {
                        children: JsxRuntime.jsx(leftIcon.make, {
                              size: "MD",
                              color: "LighterGray"
                            }),
                        className: css.leftIcon
                      }) : null,
                JsxRuntime.jsx("input", {
                      ref: props.setRef,
                      className: Cx.cx([
                            css.input,
                            disabled ? css.disabled : "",
                            tmp,
                            tmp$1,
                            leftIcon !== undefined ? css.withLeftSideAddon : "",
                            grouped ? css.grouped : "",
                            className
                          ]),
                      id: props.id,
                      autoComplete: autoCompleteToString(autoComplete),
                      autoFocus: autoFocus,
                      disabled: disabled,
                      placeholder: placeholder,
                      type: domInputType(type_),
                      value: props.value,
                      onKeyUp: props.onKeyUp,
                      onFocus: props.onFocus,
                      onBlur: props.onBlur,
                      onChange: props.onChange
                    }),
                tmp$2
              ],
              className: Cx.cx([
                    css.wrapper,
                    wrapperClassName
                  ])
            });
}

var make = TextField;

export {
  css ,
  autoCompleteToString ,
  domInputType ,
  make ,
}
/* css Not a pure module */
