// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ControlScss from "./Control.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = ControlScss;

function Control$BaseControl(props) {
  var onClick = props.onClick;
  var __className = props.className;
  var __inline = props.inline;
  var inline = __inline !== undefined ? __inline : false;
  var className = __className !== undefined ? __className : "";
  var element = React.useRef(null);
  return JsxRuntime.jsx("button", {
              children: props.children,
              ref: Caml_option.some(element),
              className: Cx.cx([
                    css.control,
                    inline ? css.inline : "",
                    className
                  ]),
              id: props.id,
              style: props.style,
              disabled: props.disabled,
              type: "button",
              onClick: (function ($$event) {
                  Belt_Option.map(onClick, (function (fn) {
                          fn($$event);
                        }));
                  Belt_Option.map(Caml_option.nullable_to_opt(element.current), (function (element) {
                          element.blur();
                        }));
                }),
              onMouseDown: props.onMouseDown,
              onTouchStart: props.onTouchStart
            });
}

var BaseControl = {
  make: Control$BaseControl
};

function Control$AsLink(props) {
  var __className = props.className;
  var __inline = props.inline;
  var __color = props.color;
  var color = __color !== undefined ? __color : "Teal";
  var inline = __inline !== undefined ? __inline : false;
  var className = __className !== undefined ? __className : "";
  var tmp;
  switch (color) {
    case "Teal" :
        tmp = css.teal;
        break;
    case "White" :
        tmp = css.white;
        break;
    case "Gray" :
        tmp = css.gray;
        break;
    
  }
  return JsxRuntime.jsx(Control$BaseControl, {
              inline: inline,
              className: Cx.cx([
                    css.link,
                    tmp,
                    className
                  ]),
              onClick: props.onClick,
              children: props.children
            });
}

var AsLink = {
  make: Control$AsLink
};

var make = Control$BaseControl;

export {
  css ,
  BaseControl ,
  AsLink ,
  make ,
}
/* css Not a pure module */
